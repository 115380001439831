<div [@fadeInTrigger] [@fadeOutTrigger] class="modal">
    <div class="modal-container ani">
        <div class="header ani">
            <span>RULES</span>
            <img (click)='rulesModal()' src="assets/images/icon-close.svg" alt="close">
        </div>
        <div class="content">
            <img src="assets/images/image-rules-bonus.svg" alt="rules">
        </div>
    </div>
</div>