<header class="header">
    <div class="header-content">
        <div class="game-name">
            <span>ROCK</span>
            <span>PAPER</span>
            <span>SCISSORS</span>
            <span>LIZARD</span>
            <span>SPOK</span>
        </div>
        <div class="score">
            <div class="score-box">
                <span class="score-title">SCORE</span>
                <span class="score-number">{{gameScore}}</span>
            </div>
        </div>
    </div>
</header>