<div [class]="'body '+screenType">
    <div [@gameInitial]='gameStep+"-"+screenType' class="game initial">
        <div class="scissors-box">
            <div class="btn-container btn-container-scissors" (click)='changeHandState("scissors")'>
                <div class="btn scissors-gradient">
                    <span class="btn-shadow-inside"></span>
                    <img class="btn-image" src="assets/images/icon-scissors.svg" alt="scissors">
                </div>
            </div>
        </div>
        <div class="spock-paper-box">
            <div class="btn-container btn-container-spock" (click)='changeHandState("spock")'>
                <div class="btn spock-gradient">
                    <span class="btn-shadow-inside"></span>
                    <img class="btn-image" src="assets/images/icon-spock.svg" alt="spock">
                </div>
            </div>
            <div class="btn-container btn-container-paper" (click)='changeHandState("paper")'>
                <div class="btn paper-gradient">
                    <span class="btn-shadow-inside"></span>
                    <img class="btn-image" src="assets/images/icon-paper.svg" alt="paper">
                </div>
            </div>
        </div>
        <div class="lizard-rock-box">
            <div class="btn-container btn-container-lizard" (click)='changeHandState("lizard")'>
                <div class="btn lizard-gradient">
                    <span class="btn-shadow-inside"></span>
                    <img class="btn-image" src="assets/images/icon-lizard.svg" alt="lizard">
                </div>
            </div>
            <div class="btn-container btn-container-rock" (click)='changeHandState("rock")'>
                <div class="btn rock-gradient">
                    <span class="btn-shadow-inside"></span>
                    <img class="btn-image" src="assets/images/icon-rock.svg" alt="rock">
                </div>
            </div>
        </div>
    </div>
    <div [@gameCombat]='gameStep+"-"+screenType' class="game combat">
        <div [@gameCombatResult]='gameStep+"-"+screenType' class="combat-box">
            <div [class]="'btn-container btn-container-'+userPicked">
                <span class="picked-text">YOU PICKED</span>
                <div [class]="'btn '+userPicked+'-gradient'">
                    <span class="btn-shadow-inside"></span>
                    <img class="btn-image" [src]="'assets/images/icon-'+userPicked+'.svg'" [alt]="userPicked">
                </div>
            </div>
            <div [@gameCombatResultText]='gameStep+"-"+screenType' class="result-container">
                <span *ngIf='!win' class="result-text">YOU LOSE</span>
                <span *ngIf='win' class="result-text">YOU WIN</span>
                <button (click)='restartGame()' *ngIf='!win' class="play-again-btn">PLAY AGAIN</button>
                <button (click)='restartGame()' *ngIf='win' class="play-again-btn">NEXT ROUND</button>
            </div>
            <div [class]="'btn-container btn-container-'+ramdomClass">
                <span class="picked-text">RIVAL PICKED</span>
                <div [class]="'btn '+ramdomClass+'-gradient'">
                    <span class="btn-shadow-inside"></span>
                    <img class="btn-image" [src]="'assets/images/icon-'+ramdomClass+'.svg'" [alt]="ramdomClass">
                </div>
            </div>
        </div>
    </div>
    <div class="rules">
        <button (click)='rulesModal()'>RULES</button>
    </div>
</div>

